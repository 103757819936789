<template>
  <div class="page">
    <div class="details-box">
      <div class="title-box">
        <div class="title-top">
          <span>{{ demand.title }}</span>
          <span class="salary">{{ demand.remuneration }}</span>
        </div>
        <div class="title-bottom">
          <span>
            <van-icon name="location-o" /><span class="title-tip">{{
              demand.workingPlaceName
            }}</span>
          </span>
          <!-- <span style="margin-left: 15px">
            <van-icon name="coupon-o" /><span class="salary" >经验两年以上</span>
          </span> -->
          <span style="margin-left: 15px">
            <van-icon name="underway-o" /><span class="salary"
              >工期{{ demand.projectDuration }} 天</span
            >
          </span>
        </div>
      </div>

      <div class="progress">
<!--        <div class="progress-van">-->
<!--          <h5>进度</h5>-->
<!--          <van-progress :percentage="50" />-->
<!--        </div>-->
        <div class="card">
          <div class="boss-photo">
            <img :src="demand.createdUserAvatar" alt="" />
          </div>
          <div class="boss-info">
            <div class="con-top">
              <div class="boss-name">
                {{ demand.createdUserName }}
                <!-- <span>18812345626</span> -->
              </div>
            </div>
            <div class="release">发布于: {{ demand.createdDate }}</div>
          </div>
        </div>
      </div>
      <!--项目描述-->
      <div class="project">
        <div class="describe">
          <div class="tip">项目描述</div>
          <div class="work-type">
            所需工种:
            <span v-for="(item, index) in demand.workerSkill" :key="index">
              <span>{{ item }}</span>
            </span>
          </div>
          <div class="treatment">
            <p v-for="(item, i) in demand.workerInfos" :key="i">
              <van-icon name="info-o" color="#7A9DE5" />:
              {{ item.workType }} 招{{ item.peopleQty }}人,{{
                item.remuneration
              }}
            </p>
          </div>
          <div class="first" v-text="demand.content"></div>
        </div>
      </div>
      <!--工作地址-->
      <div class="work-place">
        <div class="tip">工作地址</div>
        <p v-text="demand.workingPlaceName"></p>
        <!-- <div class="map"></div> -->
      </div>

      <!--安全提示-->
      <div class="safety-tip">
        <div class="tip">
          <span>申请人列表</span>
          <div class="con-list">
            <div class="con-item">
              <div class="user-name">田小强</div>
              <div class="phone"><a href="tel:18453170418">18453170418</a></div>
              <div class="create-date">2021-10-10 12:00:00</div>
            </div>
          </div>
        </div>

        <div class="map"></div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-btn">
        <van-button round type="info" v-if="demand.status=='REVIEW'||demand.status=='FAIL'" plain style="width: 100px" @click="onSuccess" >通过</van-button>
        <van-button round type="info" plain style="width: 100px;margin-left: 30px" @click="showFail=true" >拒绝</van-button>
      </div>
    </div>
    <van-dialog v-model="showFail" title="拒绝原因" show-cancel-button @confirm="onFail" @cancel="onShowFail">
      <van-field v-model="dto.failRemark" placeholder="请输入拒绝原因" />
    </van-dialog>
  </div>
</template>


<script>
import { get, getPhone } from "../../api/demand/index";
import { Dialog, Toast, Notify } from "vant";
import { getTicket } from "../../api/auth";
import wx from "weixin-js-sdk";
import * as demandApi from "../../api/demand";
export default {
  props: ["id"],
  data() {
    return {
      showFail:false,
      mask: false,
      tel: undefined,
      demand: {
        photo: require("@/assets/images/qizhongji.png"),
        recruitmentTitle: "高新汉裕金谷招水电工8名",
        describe:
          "要求:干活实在,有实力的人,项目稳定,资金没有问题,包吃包住,地点是在济南高新汉裕金谷,具体事宜请与项目经理王老板电话联系.",
        workPlace: "济南高新",
        days: "6天",
        date: "2021.11.4",
        salary: "300元/日",
      },
      pid: undefined,
      dto:{
        id: undefined,
        failRemark: ""
      }
    };
  },
  created(e) {
    console.info("detail created:", this.$route.query.pid);
    this.pid = this.$route.query.pid;

  },
  mounted() {
    this.loadData();
  },
  methods: {
    async onSuccess(){
      this.dto.id=this.demand.id
      console.info("onSuccess",this.dto)
      let r = await demandApi.adminOK(this.dto);
      console.info("onSuccess",this.dto)
      // Notify({ type: 'primary', message: '审核通过' });
      Dialog.confirm({
        title: "操作结果",
        message: "审核通过",
      }).then(() => {})
      .catch(() => {});
    },
    onShowFail(){
      this.dto.id=this.demand.id
      this.showFail=true
    },
    async onFail(e){
      this.dto.id=this.demand.id
      let r = await demandApi.adminFail(this.dto);
      // Notify({ type: 'danger', message: '拒绝成功' });
      Dialog.confirm({
        title: "操作结果",
        message: "拒绝成功",
      }).then(() => {})
          .catch(() => {});
    },
    async loadData() {
      this.demand = await get(this.id);

    },

    go(url){
      this.$router.push(url)
    }
  },
};
</script>

<style lang="less" scoped>
.con-item {
  display: flex;
  justify-content: space-between;
  color: #747577;
  padding: 10px 1px;
  a:link {
    color: #1989fa;
  }
}
.mask {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.2);
}
.maskImg {
  position: fixed;
  top: 6px;
  right: 26px;
  z-index: 100000;
  //width: 507upx;
}
.maskImg img {
  width: 80px;
}
.share-txt{
  color: #fff;
  text-align: center;
  width: 100%;
  margin-top: 60px;
  margin-right: 25px;
  .title{
    font-size: 22px;
  }
}


.page {
  background: #fafafa;
}
h5{
  font-size: 1.1em;
  margin-block-start: 0.9em;
  margin-block-end: 1em;
}
.van-icon{
  top: 2px;
}
.details-box {
  min-height: calc(100vh - 90px);
  .title-box {
    background: #ffffff;
    padding: 10px;

    .title-top {
      font-size: 18px;
      font-weight: 600;

      .salary {
        float: right;
        color: #f38003;
      }
    }

    .title-bottom {
      padding: 5px 0;
      color: #747577;
      font-size: 14px;
    }
  }

  .progress {
    margin-top: 5px;
    background: #ffffff;
    height: 75px;
    padding: 1px 10px;

    .progress-van {
      border-bottom: 1px #f7f7f7 solid;
      height: 60px;
    }
    .card {
      display: flex;
      justify-content: space-between;
      .boss-photo {
        width: 20%;
        padding: 10px 0 10px 10px;
        img {
          width: 45px;
          height: 45px;
        }
      }
      .boss-info {
        width: 80%;
        padding: 10px 10px 10px 0;
        span {
          color: #c3c3c3;
          font-size: 14px;
        }
        .release {
          margin-top: 5px;
          color: #747577;
        }
      }
    }
  }

  .project {
    background: #ffffff;
    /*border: 1px #F38003 solid;*/
    height: 220px;
    padding: 0 10px;
    .describe {
      border-bottom: 1px #f7f7f7 solid;
      .tip {
        font-weight: 600;
        font-size: 1.1em;
        margin: 5px 0;
        padding-top: 5px;
      }
      .work-type {
        color: #3c3c3c;
        font-size: 14px;
        margin-bottom: 10px;
        span {
          padding: 3px;
          background: #f0f5ff;
          color: #6e8fd1;
        }
      }
      .treatment {
        font-size: 14px;
        color: #3c3c3c;
      }
      .first {
        border-bottom: 1px #f7f7f7 solid;
        background: #fafafa;
        color: #3c3c3c;
        font-size: 14px;
        padding: 5px;
        min-height: 140px;
      }
    }
  }
  .work-place {
    padding: 0 10px;
    .tip {
      font-weight: 600;
      font-size: 1.1em;
      margin: 5px 0;
      padding-top: 5px;
    }
    .map {
      height: 160px;
      border: 1px solid #f38003;
    }
  }
  .safety-tip {
    border-bottom: 1px #f7f7f7 solid;
    margin-top: 10px;
    margin-bottom: 80px;
    .tip {
      font-weight: 600;
      font-size: 1.1em;
      margin: 5px 10px;
      padding-top: 5px;
      p {
        font-weight: 500;
        font-size: 14px;
        color: #6e6e6e;
        a {
          color: #1083fc;
        }
      }
    }
  }
}

.footer {
  background: #ffffff;
  padding: 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .footer-btn {
    display: flex;
    justify-content: center;
  }
}
</style>
